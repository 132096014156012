export const adminIds = [
    '430362324',
    '230053777',
    '142591664',
    '311022698',
    '353442275',
    '220956415',
    '717659742',
//anna Pinchugova - removed
//  '331766012',
//Lada Semenova
    '212199514',
//stepan dev
    '707020716',
]
